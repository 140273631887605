.ant-modal {
    display: flex;
    justify-content: center;
}

.share-modal .ant-modal-content{
    width: 360px;
}

.share-modal .ant-modal-content .icons{
    display: flex;
    justify-content: space-around;
    font-size: 12px;
}

.share-modal .ant-modal-content .icons div .label{
    text-align: center;
}

.share-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item{
    margin-bottom: 0px;
}

.share-modal .ant-modal-content .ant-modal-footer{
    display: none;
}

.share-modal .ant-modal-content .icons span{
    /* border: 1px solid #f8f9fa; */
    border-radius: 50%;
    padding: 15px;
    font-size: 20px;
    background-color: #f8f9fa;
    cursor: pointer;
}
.share-modal .ant-modal-content .icons span:hover{
    background-color: RGBA(232, 130, 71, 0.13);
    color: RGBA(232, 130, 71, 1.00)
}

.share-modal .ant-modal-content .page-link{
    margin-top: 30px;
}

.share-modal .ant-modal-content .page-link .link{
    padding: 5px 10px;
    background-color: #f8f9fa;
    border: 1px solid #D3D3D3;
    margin-top: 5px;
    border-radius: 4px;
}

.share-modal .ant-modal-content .page-link .link>span{
    display: flex;
    justify-content: space-between;
}

.share-modal .ant-modal-content .page-link .link span{
    align-self: center;
}