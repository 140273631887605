
.invoice-view{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #EAEDF4 !important;
 }
.invoice-view .app-header{
    width: 80%;
    background: transparent !important;
    box-shadow:none;
    margin: 10px 0px
}
.invoice-view .ant-layout-header{
    padding: 0px;
}
.invoice-view .app-header .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
}
.invoice-view .app-header .logo {
    width: auto;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.invoice-view .app-header .logo img {
    height: 70%;
    object-fit: contain;
}
.invoice-view .app-header .ant-menu{
    background: transparent;
    border: none;
}
.invoice-view .app-header .ant-menu-item {
    font-size: 18px;
    font-weight: bold;
    /* color: var(--secondary); */
}
.invoice-view .app-header .ant-btn{
    font-weight: bold;
}
/*****/
.invoice-view .ant-card-body{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
}
.invoice-view .ant-card .heading-container{
    display: flex;
    justify-content: space-between;
}
.invoice-view .ant-card .heading{
    font-size: 26px;
    font-weight: 700;
    color:#696969;
    padding: 0px;
    margin-bottom: 0px;
}
.invoice-view .ant-card .label{
    font-size: 12px;
    font-weight: 700;
    color: #A9A9A9;
}
.invoice-view .ant-card .value{
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.invoice-view .ant-card .image{
    margin: 10px;
}
.invoice-view .ant-card .amount{
    text-align: right;
}
.invoice-view .ant-card .amount .label{
    font-size: 10px;
}
.invoice-view .ant-card .amount .value{
    font-size: 24px;
    font-weight: 600;
    color: #2ecc71;
}
.invoice-view .ant-card .asset-detail{
    display: block;
    min-height: auto;
}
.invoice-view .ant-card .asset-detail .label{
    color: var(--secondary);
}
.invoice-view .ant-card .attachments .label{
    margin-bottom: 5px;
}
.invoice-view .ant-card .attachments a{
    margin-right: 10px;
}
.invoice-view .ant-card .asset-detail.asset{
    padding: 10px 15px 0px 15px !important;
}
.invoice-view .ant-card .asset-detail.duration{
    padding: 0px 15px 10px 15px !important;
}
.invoice-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.invoice-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.invoice-view .ant-card .label.desc{
    padding-top: 5px;
}

.invoice-view .ant-card .value.desc{
    text-transform: none;
    font-size: 12px;
}

.invoice-view .ant-card .ant-divider-dashed {
    border-color: var(--secondary);
    border-style: dashed;
    border-width: 2px 0 0;
}

.invoice-view .ant-card {
    border-radius: 10px;
}
.invoice-view .container .ant-collapse .ant-collapse-item{
    background: #fff;
    border: 0px;
}

.invoice-view .ant-list-item-meta-description .doc-desc{
    display: flex;
    justify-content: flex-end;
}
/*****/
.invoice-view  .app-header .ant-btn:hover{
    opacity: 0.75;
}

.invoice-view  .container{
    height: 100%;
    width: 100%;
    max-width: 80%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: flex;
}

.invoice-view  .container .images-list .ant-list-item-meta-content{
    align-self: center;
}
.invoice-view  .ant-list-item-meta-description .doc-desc{
    display:flex;
    align-self: center;
}

.invoice-view  .ant-list-item-meta-description .doc-desc h3{
    font-size: 14px;
    color: grey;
    font-weight: 600;
}
 
.invoice-view  .container .collapse .anticon.anticon-right.ant-collapse-arrow{
    color: #E88247;
    margin-right: -15px
}

.invoice-view  .container .ant-collapse .ant-collapse-item .ant-collapse-header, .invoice-view  .container .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
    padding: 0;
}

.invoice-view  .ant-list-item-meta-description .ant-btn:first-of-type {
    margin-right: 10px;
    color: var(--secondary);
}

.invoice-view  .ant-input[disabled]{
    color:grey;
    font-weight: 600;
}
.image-container{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    background: RGBA(226, 115, 32, 0.13); 
}

.image-container.empty{
    height: 350px;
    position: relative;
}

.image-container.empty img{
    width: 250px;
}

.image {
    position: relative;
    height: 350px;
    width: 300px;
    display: flex !important;
    justify-content: center;
}

.invoice-view  .ant-carousel .slick-slide img {
    display: block;
    max-width: 100%;
}
.invoice-view  .ant-carousel .slick-dots li,.invoice-view  .ant-carousel .slick-dots li.slick-active{
    width: auto;
}
.invoice-view  .ant-carousel .slick-dots li button {
    width: 6px !important;
    height: 5px !important;
    border-radius: 10px !important;
}
.invoice-view  .ant-input[disabled]{
    background-color: #fff;
    border: 0px;
    padding: 0px;
    resize: none;
}

.invoice-view  .image-actions{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row
}

.invoice-view  .image-actions .ant-btn{
    color: var(--secondary);
}


.invoice-view  .ant-card .ant-card-body .basic-details{
    padding: 10px 20px;
}
.invoice-view  .ant-card .ant-card-body .basic-details h1{
    margin: 0px;
}
.invoice-view  .ant-card .ant-card-body .ant-divider{
    margin: 0px;
}
/* .image-actions > div{
    background: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-left: 5px;
    cursor: pointer;
}
.asset-detail .image-actions>div:hover{
    background-color: var(--secondary);
    opacity: 0.5;
} */


@media only screen and (max-width: 1024px) {
    .invoice-view  .container {
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
    .invoice-view  .app-header {
        width: 90%;
    }
    .invoice-view  .app-header .header-container {
        max-width: 100%;
        margin: 0;
        width: 100% !important
    }
}
@media only screen and (max-width: 420px) {
    .invoice-view  .app-header .ant-menu-item{
        padding-right: 0
    }
    /* .invoice-view  .ant-card .ant-avatar {
        width: 100px !important;
    } */
    .invoice-view  .ant-form .ant-form-item {
        margin-bottom:10px
    }
    .invoice-view  .ant-form .ant-form-item .ant-form-item-label {
        padding: 0
    }
    .invoice-view  .ant-list-item-meta-description .ant-btn:first-of-type {
        margin-bottom: 5px;
    }

    .image {
        position: relative;
        height: 200px;
        display: flex !important;
        justify-content: center;
    }

    .invoice-view  .image-container.empty{
        height: 200px;
        position: relative;
    }
}

