.asset-page .page-content .report-container > div{
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    height: 100%;
}
.asset-page .page-content .report-container>div::before,
.asset-page .page-content .report-container>div::after{
    display: none;
}

.asset-page .page-content .report-container > div > div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: space-around;
}

.asset-page .page-content .report-container > div > div p{
    margin: 0;
}

.asset-page .page-content .report-container .amount p:nth-child(2){
    font-size: 24px;
}
