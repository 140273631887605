.receipt-history .receipt{
    display: flex;
    justify-content: space-between;
}
.receipt-history .button-container .ant-btn{
    margin: 0px 5px;
}
.receipt-history .receipt .desc{
    font-weight: 700;
}
.receipt-history .receipt .amount{
    color: #2ecc71;
}
.receipt-history .receipt .action-container{
    display: flex;
    justify-content: space-around
}