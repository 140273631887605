.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout{
  min-height: 100vh !important;
  overflow-x: scroll;
  background-color: #E9EDF5
}
.ant-layout-content {
  background-color: #E9EDF5;
  overflow-y: scroll;
  height: 100vh;
}
.page-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  /* background-color: RGBA(240, 242, 245, 1.00); */
}


.empty-large.ant-empty {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 80%;
}
.empty-large .ant-empty-image{
  height: 50vh !important;
}
.empty-large .ant-empty-image img{
  width: 100%;
}
.empty-large p{
  font-size: 18px;
}
.empty-large p a{
  font-weight: bold;
}


/* Page Heading */
.page-content {
  margin: 0px auto;
  padding-top: 50px;
  width: 90%;
  max-width: 800px;
}

.page-content .heading {
  display: flex;
  justify-content: space-between;
  padding: 0 10px
}

.page-content .heading h3 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
}

.page-content .heading .button-container button {
  margin: 0px 5px;
  font-weight: 700;
}







/* 
----------------------------------
----------------------------------
----------------------------------
------ CUSTOM STYLES ANTD --------
----------------------------------
----------------------------------
----------------------------------
*/

/* Card */
.ant-card{
  overflow: hidden;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.025);
}

/* Modal */
.ant-modal-content {
  overflow: hidden;
  border-radius: 16px;
}
.ant-modal-content .ant-modal-header{
  border: none;
  padding-bottom: 0;
}
.ant-modal-content .ant-modal-footer {
  border: none;
  padding-top: 0;
}

/* Table */
.ant-table{
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.025);
}
.ant-table .ant-table-expanded-row td{
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.ant-table.ant-table thead>tr>th{
  background-color:RGBA(226, 148, 86, 0.075);
  font-weight: 600;
  /* font-size: 16px; */
}
.ant-table tr>td{
  background-color: #fff
}

/* Alert */
.ant-alert{
  border: none !important;
  margin-top: 10px !important;
}

/* BreadCrumb */
.ant-breadcrumb{
  font-size:20px !important
}
.ant-breadcrumb a{
  color: var(--secondary) !important;
  font-weight: bold;
  /* border-bottom: 2px solid var(--secondary); */
}
.ant-breadcrumb .ant-btn {
  font-weight: bold;
  font-size: 20px;
  padding-right: 0;
  padding-left: 5px;
}
.ant-breadcrumb .ant-btn .anticon{
  font-size: 20px;
}

/* Form */
.ant-form-vertical .ant-form-item-label{
  padding-bottom: 3px !important;
}
.ant-form-vertical .ant-form-item{
  margin-bottom: 16px !important;
}
.custom-table-row {
  background-color:#ccc !important;
}

/* Ant Tag */
.ant-tag{
  text-transform: capitalize;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
