@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --secondary: #E88247;
  --primary: #fff;

  --background: #f6f9ff;
  --foreground: #ffffff;
}
*{
  font-family: 'Open Sans',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.sidebar {
    background: #fff !important
}

.sidebar .sidebar-inner-container {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.sidebar .sidebar-inner-container .logo-container img.sidebar-logo {
    width: 100%;
    padding: 36px 42px 50px
}

.sidebar .ant-menu-item {
    background-color: transparent !important;
    color: RGBA(26, 42, 78, 0.5);
    font-weight: 600;
    /* font-size:16px; */
    height: 50px !important;
    line-height: 50px !important;
    padding-left: 56px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    transition: all 0.4s ease-in-out !important;
}

.sidebar .ant-menu-item.ant-menu-item-selected {
    background-color: RGBA(232, 130, 71, 0.136) !important;
    color: var(--secondary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 16px;
    width: calc(100% - 15px);
    box-shadow: 3px 3px 10px 4px rgba(0, 0, 0, 0.036);
}

.sidebar .sidebar-inner-container .stats-container {
    margin-top:auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 20px;
}
.sidebar .sidebar-inner-container .stats-container .ant-card{
    background: RGBA(250, 239, 231, 1.00);
    box-shadow: none;
}
.sidebar .sidebar-inner-container .stats-container .ant-statistic-title {
    /* color: rgba(0, 0, 0, 0.6); */
    color: var(--secondary);
    font-weight: 600;
    text-transform: uppercase;
}
.sidebar .sidebar-inner-container .stats-container .ant-statistic-content-value {
    font-weight: 600;
}
.login-page{
    background-color: #fff;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.login-page .page-content{
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
}
.login-page img.bg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    z-index: 9;
}
.login-page .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.36);
}
.login-page .page-content{
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.login-page .page-content .ant-row{
    width: 100%;
}
.login-page .ant-card.parent{
    z-index: 999;
    max-width: 960px;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
.login-page .ant-card.parent .ant-card-body{
    -webkit-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    display: flex;
    padding: 0;
}
.login-page .ant-card.parent .img-holder{
    /* margin-right: 2px; */
    width:100%;
    height: 500px;
    background-color: RGBA(232, 130, 71, 0.13);
    position: relative
}
.login-page .ant-card.parent .img-holder img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover
}
.login-page .ant-card.parent .form-container{
    /* margin-right: 2px; */
    padding: 0 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}
.login-page .ant-card.parent .form-container img.logo{
    width: 200px;
    margin-bottom: 36px
}


.login-page .page-content .form{
    padding: 10px 0px;
    -webkit-justify-content:center;
            justify-content:center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 360px;
}

.login-page .page-content .form h2{
    font-weight: bold;
    color: #999;
}

.login-page .page-content .errMsg{
    margin-bottom: 20px;
}

.login-page .page-content .form .inner{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 15px;
    -webkit-align-items: center;
            align-items: center;
}
.login-page .page-content .form .inner .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label{
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
}
.login-page .page-content .ant-card-rtl .form .inner .react-tel-input {
    direction: ltr;
}
.login-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}

.login-page .page-content .form .inner .item{
    font-size: 16px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.login-page .page-content .link{
    font-size: 16px;
    margin: 0px 100px;
    max-width: 400px;
}


/* .login-page .left-poster{
    background: linear-gradient(135deg,  #f19f5f 30%,#e06631 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f19f5f', endColorstr='#e06631',GradientType=1 );
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-page .left-poster img{
    width: 70%;
    align-self: center;
    z-index: 2;
}

.login-page .left-poster .overlay-gradient {
    background: linear-gradient( to left, rgba(68, 66, 132, 0.3) 50%, rgba(68, 66, 132, 0.3) 100% );
    left: 0;
    top: 0;
    height: 100%;
    width: 40%;
    position: absolute;
    z-index: 1;
}

.login-page .page-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100vh;
    position: relative;
}
.login-page .page-content img{

}

.login-page .page-content .header-container{
    display: none;
}

.login-page .page-content .form{
    padding: 10px 100px;
    justify-content:center;
    display: flex;
    flex-direction: column;
    max-width: 640px;
}

.login-page .page-content .form h2{
    font-weight: 700;
    color: var(--secondary);
}

.login-page .page-content .errMsg{
    margin-bottom: 20px;
}

.login-page .page-content .form .inner{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
    max-width: 400px;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(1){
    padding-right: 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(2){
    padding: 5px 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(3){
    padding-left: 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label{
    display: flex;
    width: 100%;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
}


.login-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}

.login-page .page-content .form .inner .item{
    font-size: 16px;
    flex-grow: 1;
}

.login-page .page-content .link{
    font-size: 16px;
    margin: 0px 100px;
    max-width: 400px;
}

@media only screen and (max-width: 1024px) {
    .login-page .left-poster{
        display: none;
    }

    .login-page .page-content .header-container{
        display: block;
    }

    .login-page .page-content .form-container,.login-page .page-content .link-container {
        display: flex;
        justify-content: center;
    }

    .login-page .page-content .form-container .form{
        margin:20px 0px;
        padding: 0px;
        width: 400px;
    }

}

@media only screen and (max-width: 420px) {
    .login-page .page-content .form-container .form{
        margin:0px 20px;
    }

    .login-page .page-content .link{
        margin: 0px 20px;
    }
} */
.add-asset-modal .form p{
    margin-top: 20px;
}

.add-asset-modal .form .errMsg{
    width: 100%;
}

.add-asset-modal .form .ant-row{
    margin-bottom: 0px;
}
.add-asset-modal .form .status .ant-select{
    width: 100%;
}

.add-asset-modal .form .date-picker div.ant-picker.input-item{
    width: 100%;
}

.add-asset-modal .form .doc-type span.ant-tag{
    width: 100%;
    height: 100%;
    padding-top: 2%;
    text-align: center;
    font-size: 14px;
}


.assets-table .ant-avatar{
    width: 100% !important;
    background-color: #FBEBE2;
}
.assets-table .ant-col p{
    line-height:  18px;
    margin-bottom: 8px;
}
.ant-modal {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.share-modal .ant-modal-content{
    width: 360px;
}

.share-modal .ant-modal-content .icons{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    font-size: 12px;
}

.share-modal .ant-modal-content .icons div .label{
    text-align: center;
}

.share-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item{
    margin-bottom: 0px;
}

.share-modal .ant-modal-content .ant-modal-footer{
    display: none;
}

.share-modal .ant-modal-content .icons span{
    /* border: 1px solid #f8f9fa; */
    border-radius: 50%;
    padding: 15px;
    font-size: 20px;
    background-color: #f8f9fa;
    cursor: pointer;
}
.share-modal .ant-modal-content .icons span:hover{
    background-color: RGBA(232, 130, 71, 0.13);
    color: RGBA(232, 130, 71, 1.00)
}

.share-modal .ant-modal-content .page-link{
    margin-top: 30px;
}

.share-modal .ant-modal-content .page-link .link{
    padding: 5px 10px;
    background-color: #f8f9fa;
    border: 1px solid #D3D3D3;
    margin-top: 5px;
    border-radius: 4px;
}

.share-modal .ant-modal-content .page-link .link>span{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.share-modal .ant-modal-content .page-link .link span{
    -webkit-align-self: center;
            align-self: center;
}
.connected-rental{
    width: 100%;
    margin: auto !important;
    transition: all 0.4s ease-in-out;
    /* margin-bottom: 20px !important; */
}

.connected-rental .button-container button{
    margin: 0px 5px;
    font-weight: 700;
}

.connected-rental .button-container button.rental{
    background-color:#2ecc71;
    border: #2ecc71;
}

.connected-rental .button-container button.expense{
    background-color:#e74c3c;
    border: #e74c3c;
}

.connected-rental .action-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.add-rental-modal .input-item{
    width: 100%;
}

.add-rental-modal .input-item.rate {
    border: 1px solid #eeeeee;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
}

.add-rental-modal .input-item.rate::-webkit-input-placeholder{
    color: #c4c4c4
}

.add-rental-modal .input-item.rate:-ms-input-placeholder{
    color: #c4c4c4
}

.add-rental-modal .input-item.rate::placeholder{
    color: #c4c4c4
}

.add-rental-modal .input-item.rate:hover, .add-rental-modal .input-item.rate:focus{
    outline: none;
    border: 1px solid #f5a772;
}

.add-rental-modal .ant-row{
    margin-bottom: 0px;
}

.add-rental-modal .errMsg{
    width: 100%;
}

.add-rental-modal .ant-form-item-label{
    padding-bottom: 3px !important; 
}

.add-rental-modal .hidden{
    display: none;
}
.add-contact-modal .ant-col .ant-row{
    margin-bottom: 0px;
}

.add-contact-modal .input-item{
    width: 100%
}
.add-contact-modal .react-tel-input .form-control{
    width: 100%;
    height: 30px
}
.add-contact-modal .react-tel-input .country-list{
    position: fixed !important;
}
.add-contact-modal .errMsg{
    width: 100%;
}
.add-contact-modal .react-tel-input .country-list .search-box{
    width: 90%;
}

.doc-section{
    background-color: white;
    width: 100%;
    margin: 0px 0px;
}

.doc-section .header-section{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* margin-bottom: 25px; */
    /* height: 40px; */
}

.doc-section .header-section p {
    font-weight: 500;
}

.doc-section .doc-container{
    /* height: 72%; */
    /* max-height: 150px;
    overflow: scroll; */
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.doc-section .doc-container .doc-row{
    margin-bottom:10px
}
.doc-section .doc-container .ant-form,
.doc-section .doc-container .ant-form-item,
.doc-section .doc-container .ant-form-item .ant-picker {
    width: 100%;
}
.doc-section .doc-container .image-container img{
    height: 80px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 2px 8px 10px 0px rgba(0,0,0,0.13);
}
.doc-section .doc-container .input-container{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}
.doc-section .doc-container .action-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.doc-section .doc-container .action-container .ant-form-item-control-input-content {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
}

.asset-details{
   display: -webkit-flex;
   display: flex;
   /*flex-direction: column;*/
   width: 100%;
   margin: 0px auto;
   transition: all 0.4s ease-in-out;
   min-height: calc(100vh -64px);
   -webkit-justify-content: flex-end;
           justify-content: flex-end;   
}
.asset-details.fade-in{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0)
}
.asset-details.fade-out{
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px)
}

.asset-details .buttons{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.asset-details .buttons button:nth-child(2){
    margin-left: 10px;
}

.asset-details .basic{
    width: 100%;
    height: auto;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.asset-details .basic > .ant-card-body{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding: 0;
}

.asset-details .basic > .ant-card-body .ant-carousel{
    width: 50%;
}
.asset-details .basic .image-container{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background: RGBA(226, 115, 32, 0.13);
}

.asset-details .basic .image-container.empty{
    width: 50%;
    position: relative;
}

.asset-details .basic .image-container .image {
    position: relative;
    height: 250px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.asset-details .image-actions{
    position: absolute;
    top: 10px;
    right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row
}
.asset-details .image-actions > div{
    background: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-left: 5px;
    cursor: pointer;
}
.asset-details .image-actions>div:hover{
    background-color: var(--secondary);
    opacity: 0.5;
}

/* .asset-details .image-actions div>span */

/* .asset-details .basic .image-container .image>.image-actions:nth-child(2) {
    right: 30px;
    top: 3px;
} */

/* .asset-details .image-actions>span {
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.asset-details .basic .image-container .image .ant-upload span{
    font-size: 20px;
    color: white;
    cursor: pointer;
} */

.asset-details .basic .image-container .slick-list{
    width: 100%;
    height: 100%;
}
/* .asset-details .basic .image-container .ant-card,.asset-details .basic .image-container .ant-card-body{
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

.asset-details .basic .image-container .ant-card-body{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.asset-details .basic .image-container img{
    width: 100%;
    height: 250px;
    object-fit: contain;
}

.asset-details .basic .basic-details{
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 20px;
}

.asset-details .basic .basic-details > div{
    margin: 5px 0px;
}

.asset-details .basic .basic-details p{
    margin: 0px;
}

.asset-details .basic .basic-details .ant-select{
    width: 120px;

}

/* 
.asset-details .doc-section .doc-container .doc-row .image-container{
    height: 100px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 15px;
}

.asset-details .doc-section .doc-container .doc-row{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}


.asset-details .doc-section .doc-container .doc-row .image-container img{
    height: 100%;
    width: 100%;
}

.asset-details .doc-section .doc-container .doc-row .input-container{
    display: flex;
}     */

/* .asset-details .doc-section .doc-container .doc-row .input-container p{
    margin: 5px;
}

.asset-details .doc-section .doc-container .doc-row .input-container.name input{
    width: 250px;
}

.asset-details .doc-section .doc-container .ant-empty .ant-empty-image{
    opacity: 0.8;
} */
.currency-modal .ant-modal-content{
    width: 300px;
}
.custom-footer.ant-layout-footer {
    text-align: center;
    background-color: transparent;
    padding: 20px 50px;
    /* padding: 10px 50px; */
    font-size: 13px;
    font-weight: 600;
}
.asset-page .page-content .report-container > div{
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-radius: 8px;
    height: 100%;
}
.asset-page .page-content .report-container>div::before,
.asset-page .page-content .report-container>div::after{
    display: none;
}

.asset-page .page-content .report-container > div > div:nth-child(2){
    display: -webkit-flex;
    display: flex;
    width: 50%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.asset-page .page-content .report-container > div > div p{
    margin: 0;
}

.asset-page .page-content .report-container .amount p:nth-child(2){
    font-size: 24px;
}

.settings-page .page-content .content{
    display: -webkit-flex;
    display: flex;
    width: 100% ;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.settings-page .page-content .ant-card-body{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: flex-start;
            align-items: flex-start
}
.settings-page .page-content .ant-card-body .ant-row{
    width: 100%;
}
.settings-page .page-content .video{
    width: 100% !important;
    height: 250px !important;
    border-radius: 8px;
    overflow: hidden;
}
/* .settings-page .page-content .ant-card-body::before,
.settings-page .page-content .ant-card-body::after {
    display: none;
} */
.settings-page .page-content .ant-card-body .form-col {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.settings-page .page-content .img-holder{
    /* width:320px; */
    height: 250px;
    position: relative;
}
.settings-page .page-content .img-holder img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover
}

.settings-page .page-content .form-container .form,
.settings-page .page-content .form-container .item {
    width: 100%;
}

/* .settings-page .page-content .form-container .form .inner .ant-col.ant-col-8:nth-child(1){
    padding-right: 0px!important;
}

.settings-page .page-content .form-container .form  .inner .ant-col.ant-col-8:nth-child(2){
    padding: 5px 0px!important;
}

.settings-page .page-content .form-container .form  .inner .ant-col.ant-col-8:nth-child(3){
    padding-left: 0px!important;
}

.settings-page .page-content .form .inner .ant-col.ant-col-8 label{
    display: flex;
    width: 100%;
} */

/* .settings-page .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
} */
.settings-page .page-content .form .inner .ant-row-rtl .react-tel-input {
    direction: ltr;
}
.settings-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}
.settings-page .page-content .form .inner .ant-radio-button-wrapper{
    text-align: center;
    width: 100%;
}
.settings-page .page-content .form .ant-form-item{
    margin: 0
}
.settings-page .page-content .form .inner {
    margin: 15px auto
}

/* .settings-page .page-content .form .inner .item{
    font-size: 16px;
    flex-grow: 1;
} */

/* .settings-page .page-content .form .inner .ant-row{
    margin-bottom: 10px;
} */

/* .settings-page .page-content .form .inner .ant-btn{
    margin-top: 10px;
} */
.contact-details .ant-col .ant-row{
    margin-bottom: 0px;
}

.contact-details .input-item{
    width: 100%
}
.contact-details .react-tel-input .form-control{
    width: 100%;
    height: 30px
}

.contact-details .errMsg{
    width: 100%;
}
.contacts-table .ant-avatar{
  width: 48px !important;
  height: 48px !important;
  color: #9f9f9f;
  font-weight: 600;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  justify-content: center;
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  background-color: RGBA(251, 235, 226, 1.00);
}

.contacts-page .page-content .report-container > div{
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-radius: 8px;
    height: 100%;
}
.contacts-page .page-content .report-container>div::before,
.contacts-page .page-content .report-container>div::after{
    display: none;
}

.contacts-page .page-content .report-container > div > div:nth-child(2){
    display: -webkit-flex;
    display: flex;
    width: 50%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.contacts-page .page-content .report-container > div > div p{
    margin: 0;
}

.contacts-page .page-content .report-container .amount p:nth-child(2){
    font-size: 24px;
}
.rentals-table{
    margin: 20px 0;
}
.rentals-table p{
    margin-bottom: 5px
}
.add-receipt .amount{ 
    border: 1px solid #eeeeee;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
    width: 100%;
}

.add-receipt .amount:focus{
  outline: none;
  border: 1px solid #f5a772;
}

.add-receipt .images{
    position: relative;
}

.add-receipt .images .close-icon{
    position: absolute;
    z-index: 1;
    left: 85px;
    color: red;
}

.add-receipt .images .image {
    display: block;
    width: 100%;
    height: auto; 
    border: 1px solid #eeeeee;
  }
.add-receipt .images .image img{
  padding: 10px;
}
.add-receipt .images .overlay {
    position: absolute;
    bottom: 10px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100px;
    height: 100px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .add-receipt .images .overlay a{
    color: #fff;
  }
  .add-receipt .images .overlay .anticon svg{
    font-size: 16px;
  }
  .images:hover .overlay {
    opacity: 1;
  }
.receipt-history .receipt{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.receipt-history .button-container .ant-btn{
    margin: 0px 5px;
}
.receipt-history .receipt .desc{
    font-weight: 700;
}
.receipt-history .receipt .amount{
    color: #2ecc71;
}
.receipt-history .receipt .action-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around
}
.rental-details .input-item {
    width: 100% !important;
}

.rental-details .input-item.rate {
    border: none;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
}

.rental-details .input-item.rate::-webkit-input-placeholder{
    color: #c4c4c4
}

.rental-details .input-item.rate:-ms-input-placeholder{
    color: #c4c4c4
}

.rental-details .input-item.rate::placeholder{
    color: #c4c4c4
}

.rental-details .input-item.rate:hover, .payment-details .input-item.rate:focus{
    outline: none;
    border: 1px solid #f5a772;
}


.rental-details .ant-row{
    margin-bottom: 10px;
}

.rental-details .hidden{
    display: none;
}

.rental-details .heading .back{
    font-size: 20px;
    font-weight: 600;
}


.rentals-page .page-content .heading .button-container button.rental{
    background-color:#2ecc71;
    border: #2ecc71;
}

.rentals-page .page-content .heading .button-container button.expense{
    background-color:#e74c3c;
    border: #e74c3c;
}
.rentals-page .page-content .report-container h3 {
    margin: 0;
}
.rentals-page .page-content .report-container .ant-btn-link {
    padding-left: 0;
}
.rentals-page .page-content .report-container > div{
    /* background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    height: 100%; */
}
.rentals-page .page-content .report-container .ant-row{
    width: 100%;
}
.rentals-page .page-content .report-container>div::before,
.rentals-page .page-content .report-container>div::after{
    display: none;
}

.rentals-page .page-content .report-container > div > div:nth-child(2){
    display: -webkit-flex;
    display: flex;
    width: 50%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.rentals-page .page-content .report-container > div > div p{
    margin: 0;
}
.rentals-page .page-content .report-container .amount{
    text-align: right;
}
.rentals-page .page-content .report-container .amount p:nth-child(2){
    font-size: 18px;
}
.splash-screen{
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgb(241, 159, 95);
    background: linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f19f5f", endColorstr="#e06631", GradientType=1);
}
.splash-screen img.bg{
    position: relative;
    width: 100%;
    object-fit: contain;
    z-index: 999;
    animation:fade 2500ms infinite;
    -webkit-animation:fade 2500ms infinite;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}
.download-page{
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #fff;
}
.download-page img.bg {
    position: relative;
    width: 90%;
    object-fit: contain;
    z-index: 999;
    margin-bottom: 36px;
    /* animation:fade 2500ms infinite;
    -webkit-animation:fade 2500ms infinite; */
}
.download-page .app-badges img{
    width: 100%;
    margin-bottom:15px
}
.download-page h3{
    text-align: center;
    font-weight: bold;
    font-size:18px;
    line-height:24px;
    margin-top: 10px;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}
.not-found-page{
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #fff;
}
.not-found-page img.bg {
    position: relative;
    width: 90%;
    object-fit: contain;
    z-index: 999;
    margin-bottom: 36px;
    /* animation:fade 2500ms infinite;
    -webkit-animation:fade 2500ms infinite; */
}
.not-found-page .app-badges img{
    width: 100%;
    margin-bottom:15px
}
.not-found-page h3{
    text-align: center;
    font-weight: bold;
    font-size:18px;
    line-height:24px;
    margin-top: 10px;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

.shared-file-viewer-page .banner-prompt {  
    width: 100%;
    position: fixed;
    z-index: 7;
    bottom: 0px;
    box-shadow: 6px 6px 21px -9px rgba(0,0,0,0.75);
}
.shared-file-viewer-page .banner-prompt .row{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: var(--secondary);
}
.shared-file-viewer-page .banner-prompt .ant-page-header-heading-left,.shared-file-viewer-page .banner-prompt .ant-page-header-heading-left .ant-page-header-heading-title{
    width: 100%;
}
.banner-prompt.app .row div:nth-child(1){
    width: 95%;
    text-align: center
}
.banner-prompt.app  .row div:nth-child(2){
    width: 5%;
    text-align: right;
  }


.shared-asset-viewer-page{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    /* background: rgb(241, 159, 95) !important; */
    /* background: -moz-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    background: -webkit-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    background: linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f19f5f", endColorstr="#e06631", GradientType=1); */
 
    background-color: #EAEDF4 !important;
 }
 
 .shared-asset-viewer-page .app-header{
     width: 80%;
     background: transparent !important;
     box-shadow:none;
     margin: 10px 0px
 }
 
 .shared-asset-viewer-page .ant-layout-header{
     padding: 0px;
 }
 
 .shared-asset-viewer-page .app-header .header-container {
     padding: 0px 10px;
     width: 100%;
     height: 100%;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
             flex-direction: row;
     -webkit-justify-content: space-between;
             justify-content: space-between;
     margin: 0 auto;
     position: relative;
 }
 
 
 .shared-asset-viewer-page .app-header .logo {
     width: auto;
     height: 100%;
     margin: 0;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
             justify-content: center;
     -webkit-align-items: center;
             align-items: center;
 }
 
 .shared-asset-viewer-page .app-header .logo img {
     height: 70%;
     object-fit: contain;
 }
 .shared-asset-viewer-page .app-header .ant-menu{
     background: transparent;
     border: none;
 }
 .shared-asset-viewer-page .app-header .ant-menu-item {
     font-size: 18px;
     font-weight: bold;
     /* color: var(--secondary); */
 }
 .shared-asset-viewer-page .app-header .ant-btn{
     font-weight: bold;
     /* border-color: #fff;
     color: #fff; */
 }
 .shared-asset-viewer-page .app-header .ant-btn:hover{
     opacity: 0.75;
     /* background-color: rgba(255, 255, 255,0.5) !important; */
 }
 
 .shared-asset-viewer-page .container{
     height: 100%;
     width: 100%;
     max-width: 80%;
     overflow-y: scroll;
     padding: 0px;
     margin: 0px;
     display: -webkit-flex;
     display: flex;
 }
 
 .shared-asset-viewer-page .container .images-list .ant-list-item-meta-content{
     -webkit-align-self: center;
             align-self: center;
 }
 .shared-asset-viewer-page .ant-list-item-meta-description .doc-desc{
     display:-webkit-flex;
     display:flex;
     -webkit-justify-content: space-between;
             justify-content: space-between;
     -webkit-align-self: center;
             align-self: center;
 }
 
 .shared-asset-viewer-page .ant-list-item-meta-description .doc-desc h3{
     font-size: 14px;
     color: grey;
     font-weight: 600;
 }
  
 .shared-asset-viewer-page .container .collapse .anticon.anticon-right.ant-collapse-arrow{
     color: #E88247;
     margin-right: -15px
 }
 .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item{
     background: #fff;
     border: 0px;
     padding: 10px 20px;
 }
 
 .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item .ant-collapse-header, .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
     padding: 0;
 }
 
 .shared-asset-viewer-page .ant-list-item-meta-description .ant-btn:first-of-type {
     margin-right: 10px;
     color: var(--secondary);
 }
 
 .shared-asset-viewer-page textarea.ant-input {
     min-height: 80px;
 }
 .shared-asset-viewer-page .ant-input[disabled]{
     color:grey;
     font-weight: 600;
 }
 .image-container{
     width: 100%;
     height: 100%;
     object-fit: contain;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
             justify-content: center;
     background: RGBA(226, 115, 32, 0.13); 
 }
 
 .image-container.empty{
     height: 350px;
     position: relative;
 }
 
 .image-container.empty img{
     width: 250px;
 }
 
 .image {
     position: relative;
     height: 350px;
     width: 300px;
     display: -webkit-flex !important;
     display: flex !important;
     -webkit-justify-content: center;
             justify-content: center;
 }
 
 .shared-asset-viewer-page .ant-carousel .slick-slide img {
     display: block;
     max-width: 100%;
     object-fit: cover;
 }
 .shared-asset-viewer-page .ant-carousel .slick-dots li,.shared-asset-viewer-page .ant-carousel .slick-dots li.slick-active{
     width: auto;
 }
 .shared-asset-viewer-page .ant-carousel .slick-dots li button {
     width: 6px !important;
     height: 5px !important;
     border-radius: 10px !important;
 }
 .shared-asset-viewer-page .ant-input[disabled]{
     background-color: #fff;
     border: 0px;
     padding: 0px;
     resize: none;
 }
 
 .shared-asset-viewer-page .image-actions{
     position: absolute;
     top: 10px;
     right: 10px;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: row;
             flex-direction: row
 }
 
 .shared-asset-viewer-page .image-actions .ant-btn{
     color: var(--secondary);
 }
 /* .shared-asset-viewer-page .ant-card {
     border-radius: 0px;
 } */
 .shared-asset-viewer-page .ant-card .ant-card-body{
     padding: 0px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details{
     padding: 10px 20px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details .name-btn{
     display: -webkit-flex;
     display: flex;
     /* justify-content: space-between; */
     -webkit-flex-direction: column;
             flex-direction: column;
     margin-bottom: 15px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details .name-btn .btn-container{
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-justify-content: center;
             justify-content: center;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details h1{
     margin: 0px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .ant-divider{
     margin: 0px;
 }

 .shared-asset-viewer-page .ant-collapse-rtl > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
     right: auto;
     left: 0px;
 }
 /* .image-actions > div{
     background: #fff;
     padding: 3px 6px;
     border-radius: 4px;
     border: 1px solid #eee;
     margin-left: 5px;
     cursor: pointer;
 }
 .asset-detail .image-actions>div:hover{
     background-color: var(--secondary);
     opacity: 0.5;
 } */
 
 
 @media only screen and (max-width: 1024px) {
     .shared-asset-viewer-page .container {
         max-width: 90%;
         padding-left: 0;
         padding-right: 0;
     }
     .shared-asset-viewer-page .app-header {
         width: 90%;
     }
     .shared-asset-viewer-page .app-header .header-container {
         max-width: 100%;
         margin: 0;
         width: 100% !important
     }
 }
 @media only screen and (max-width: 420px) {
     .shared-asset-viewer-page .app-header .ant-menu-item{
         padding-right: 0
     }
     /* .shared-asset-viewer-page .ant-card .ant-avatar {
         width: 100px !important;
     } */
     .shared-asset-viewer-page .ant-form .ant-form-item {
         margin-bottom:10px
     }
     .shared-asset-viewer-page .ant-form .ant-form-item .ant-form-item-label {
         padding: 0
     }
     .shared-asset-viewer-page .ant-list-item-meta-description .ant-btn:first-of-type {
         margin-bottom: 5px;
     }
 
     .image {
         position: relative;
         height: 200px;
         display: -webkit-flex !important;
         display: flex !important;
         -webkit-justify-content: center;
                 justify-content: center;
     }
 
     .shared-asset-viewer-page .image-container.empty{
         height: 200px;
         position: relative;
     }
     .shared-asset-viewer-page .app-header .logo img {
         height: 60%;
     }
 }
 
 
.add-inquiry-modal .ant-col .ant-row{
    margin-bottom: 0px;
}

.add-inquiry-modal .input-item{
    width: 100%
}
.add-inquiry-modal .react-tel-input .form-control{
    width: 100%;
    height: 30px
}

.add-inquiry-modal .errMsg{
    width: 100%;
}

.receipt-view{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    background-color: #EAEDF4 !important;
 }
.receipt-view .app-header{
    width: 80%;
    background: transparent !important;
    box-shadow:none;
    margin: 10px 0px
}
.receipt-view .ant-layout-header{
    padding: 0px;
}
.receipt-view .app-header .header-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 auto;
    position: relative;
}
.receipt-view .app-header .logo {
    width: auto;
    height: 100%;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.receipt-view .app-header .logo img {
    height: 70%;
    object-fit: contain;
}
.receipt-view .app-header .ant-menu{
    background: transparent;
    border: none;
}
.receipt-view .app-header .ant-menu-item {
    font-size: 18px;
    font-weight: bold;
    /* color: var(--secondary); */
}
.receipt-view .app-header .ant-btn{
    font-weight: bold;
}
/*****/
.receipt-view .ant-card-body{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px 10px;
}
.receipt-view .ant-card .heading-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.receipt-view .ant-card .heading{
    font-size: 26px;
    font-weight: 700;
    color:#696969;
    padding: 0px;
    margin-bottom: 0px;
}
.receipt-view .ant-card .label{
    font-size: 12px;
    font-weight: 700;
    color: #A9A9A9;
}
.receipt-view .ant-card .value{
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.receipt-view .ant-card .image{
    margin: 10px;
}
.receipt-view .ant-card .amount{
    text-align: right;
}
.receipt-view .ant-card .amount .label{
    font-size: 10px;
}
.receipt-view .ant-card .amount .value{
    font-size: 24px;
    font-weight: 600;
    color: #2ecc71;
}
.receipt-view .ant-card .asset-detail{
    display: block;
    min-height: auto;
}
.receipt-view .ant-card .asset-detail .label{
    color: var(--secondary);
}
.receipt-view .ant-card .attachments .label{
    margin-bottom: 5px;
}
.receipt-view .ant-card .attachments a{
    margin-right: 10px;
}
.receipt-view .ant-card .asset-detail.asset{
    padding: 10px 15px 0px 15px !important;
}
.receipt-view .ant-card .asset-detail.duration{
    padding: 0px 15px 10px 15px !important;
}
.receipt-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.receipt-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.receipt-view .ant-card .label.desc{
    padding-top: 5px;
}

.receipt-view .ant-card .value.desc{
    text-transform: none;
    font-size: 12px;
}

.receipt-view .ant-card .ant-divider-dashed {
    border-color: var(--secondary);
    border-style: dashed;
    border-width: 2px 0 0;
}

.receipt-view .ant-card {
    border-radius: 10px;
}
.receipt-view .container .ant-collapse .ant-collapse-item{
    background: #fff;
    border: 0px;
}

.receipt-view .ant-list-item-meta-description .doc-desc{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
/*****/
.receipt-view  .app-header .ant-btn:hover{
    opacity: 0.75;
}

.receipt-view  .container{
    height: 100%;
    width: 100%;
    max-width: 80%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: -webkit-flex;
    display: flex;
}

.receipt-view  .container .images-list .ant-list-item-meta-content{
    -webkit-align-self: center;
            align-self: center;
}
.receipt-view  .ant-list-item-meta-description .doc-desc{
    display:-webkit-flex;
    display:flex;
    -webkit-align-self: center;
            align-self: center;
}

.receipt-view  .ant-list-item-meta-description .doc-desc h3{
    font-size: 14px;
    color: grey;
    font-weight: 600;
}
 
.receipt-view  .container .collapse .anticon.anticon-right.ant-collapse-arrow{
    color: #E88247;
    margin-right: -15px
}

.receipt-view  .container .ant-collapse .ant-collapse-item .ant-collapse-header, .receipt-view  .container .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
    padding: 0;
}

.receipt-view  .ant-list-item-meta-description .ant-btn:first-of-type {
    margin-right: 10px;
    color: var(--secondary);
}

.receipt-view  .ant-input[disabled]{
    color:grey;
    font-weight: 600;
}
.image-container{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background: RGBA(226, 115, 32, 0.13); 
}

.image-container.empty{
    height: 350px;
    position: relative;
}

.image-container.empty img{
    width: 250px;
}

.image {
    position: relative;
    height: 350px;
    width: 300px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center;
            justify-content: center;
}

.receipt-view  .ant-carousel .slick-slide img {
    display: block;
    max-width: 100%;
}
.receipt-view  .ant-carousel .slick-dots li,.receipt-view  .ant-carousel .slick-dots li.slick-active{
    width: auto;
}
.receipt-view  .ant-carousel .slick-dots li button {
    width: 6px !important;
    height: 5px !important;
    border-radius: 10px !important;
}
.receipt-view  .ant-input[disabled]{
    background-color: #fff;
    border: 0px;
    padding: 0px;
    resize: none;
}

.receipt-view  .image-actions{
    position: absolute;
    top: 10px;
    right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row
}

.receipt-view  .image-actions .ant-btn{
    color: var(--secondary);
}


.receipt-view  .ant-card .ant-card-body .basic-details{
    padding: 10px 20px;
}
.receipt-view  .ant-card .ant-card-body .basic-details h1{
    margin: 0px;
}
.receipt-view  .ant-card .ant-card-body .ant-divider{
    margin: 0px;
}
/* .image-actions > div{
    background: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-left: 5px;
    cursor: pointer;
}
.asset-detail .image-actions>div:hover{
    background-color: var(--secondary);
    opacity: 0.5;
} */


@media only screen and (max-width: 1024px) {
    .receipt-view  .container {
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
    .receipt-view  .app-header {
        width: 90%;
    }
    .receipt-view  .app-header .header-container {
        max-width: 100%;
        margin: 0;
        width: 100% !important
    }
}
@media only screen and (max-width: 420px) {
    .receipt-view  .app-header .ant-menu-item{
        padding-right: 0
    }
    /* .receipt-view  .ant-card .ant-avatar {
        width: 100px !important;
    } */
    .receipt-view  .ant-form .ant-form-item {
        margin-bottom:10px
    }
    .receipt-view  .ant-form .ant-form-item .ant-form-item-label {
        padding: 0
    }
    .receipt-view  .ant-list-item-meta-description .ant-btn:first-of-type {
        margin-bottom: 5px;
    }

    .image {
        position: relative;
        height: 200px;
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .receipt-view  .image-container.empty{
        height: 200px;
        position: relative;
    }
}



.invoice-view{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    background-color: #EAEDF4 !important;
 }
.invoice-view .app-header{
    width: 80%;
    background: transparent !important;
    box-shadow:none;
    margin: 10px 0px
}
.invoice-view .ant-layout-header{
    padding: 0px;
}
.invoice-view .app-header .header-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 auto;
    position: relative;
}
.invoice-view .app-header .logo {
    width: auto;
    height: 100%;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.invoice-view .app-header .logo img {
    height: 70%;
    object-fit: contain;
}
.invoice-view .app-header .ant-menu{
    background: transparent;
    border: none;
}
.invoice-view .app-header .ant-menu-item {
    font-size: 18px;
    font-weight: bold;
    /* color: var(--secondary); */
}
.invoice-view .app-header .ant-btn{
    font-weight: bold;
}
/*****/
.invoice-view .ant-card-body{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px 10px;
}
.invoice-view .ant-card .heading-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.invoice-view .ant-card .heading{
    font-size: 26px;
    font-weight: 700;
    color:#696969;
    padding: 0px;
    margin-bottom: 0px;
}
.invoice-view .ant-card .label{
    font-size: 12px;
    font-weight: 700;
    color: #A9A9A9;
}
.invoice-view .ant-card .value{
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.invoice-view .ant-card .image{
    margin: 10px;
}
.invoice-view .ant-card .amount{
    text-align: right;
}
.invoice-view .ant-card .amount .label{
    font-size: 10px;
}
.invoice-view .ant-card .amount .value{
    font-size: 24px;
    font-weight: 600;
    color: #2ecc71;
}
.invoice-view .ant-card .asset-detail{
    display: block;
    min-height: auto;
}
.invoice-view .ant-card .asset-detail .label{
    color: var(--secondary);
}
.invoice-view .ant-card .attachments .label{
    margin-bottom: 5px;
}
.invoice-view .ant-card .attachments a{
    margin-right: 10px;
}
.invoice-view .ant-card .asset-detail.asset{
    padding: 10px 15px 0px 15px !important;
}
.invoice-view .ant-card .asset-detail.duration{
    padding: 0px 15px 10px 15px !important;
}
.invoice-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.invoice-view .ant-card .asset-detail .value.identifier{
    color: #A9A9A9;
    font-size: 14px;
}
.invoice-view .ant-card .label.desc{
    padding-top: 5px;
}

.invoice-view .ant-card .value.desc{
    text-transform: none;
    font-size: 12px;
}

.invoice-view .ant-card .ant-divider-dashed {
    border-color: var(--secondary);
    border-style: dashed;
    border-width: 2px 0 0;
}

.invoice-view .ant-card {
    border-radius: 10px;
}
.invoice-view .container .ant-collapse .ant-collapse-item{
    background: #fff;
    border: 0px;
}

.invoice-view .ant-list-item-meta-description .doc-desc{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
/*****/
.invoice-view  .app-header .ant-btn:hover{
    opacity: 0.75;
}

.invoice-view  .container{
    height: 100%;
    width: 100%;
    max-width: 80%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: -webkit-flex;
    display: flex;
}

.invoice-view  .container .images-list .ant-list-item-meta-content{
    -webkit-align-self: center;
            align-self: center;
}
.invoice-view  .ant-list-item-meta-description .doc-desc{
    display:-webkit-flex;
    display:flex;
    -webkit-align-self: center;
            align-self: center;
}

.invoice-view  .ant-list-item-meta-description .doc-desc h3{
    font-size: 14px;
    color: grey;
    font-weight: 600;
}
 
.invoice-view  .container .collapse .anticon.anticon-right.ant-collapse-arrow{
    color: #E88247;
    margin-right: -15px
}

.invoice-view  .container .ant-collapse .ant-collapse-item .ant-collapse-header, .invoice-view  .container .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
    padding: 0;
}

.invoice-view  .ant-list-item-meta-description .ant-btn:first-of-type {
    margin-right: 10px;
    color: var(--secondary);
}

.invoice-view  .ant-input[disabled]{
    color:grey;
    font-weight: 600;
}
.image-container{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background: RGBA(226, 115, 32, 0.13); 
}

.image-container.empty{
    height: 350px;
    position: relative;
}

.image-container.empty img{
    width: 250px;
}

.image {
    position: relative;
    height: 350px;
    width: 300px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center;
            justify-content: center;
}

.invoice-view  .ant-carousel .slick-slide img {
    display: block;
    max-width: 100%;
}
.invoice-view  .ant-carousel .slick-dots li,.invoice-view  .ant-carousel .slick-dots li.slick-active{
    width: auto;
}
.invoice-view  .ant-carousel .slick-dots li button {
    width: 6px !important;
    height: 5px !important;
    border-radius: 10px !important;
}
.invoice-view  .ant-input[disabled]{
    background-color: #fff;
    border: 0px;
    padding: 0px;
    resize: none;
}

.invoice-view  .image-actions{
    position: absolute;
    top: 10px;
    right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row
}

.invoice-view  .image-actions .ant-btn{
    color: var(--secondary);
}


.invoice-view  .ant-card .ant-card-body .basic-details{
    padding: 10px 20px;
}
.invoice-view  .ant-card .ant-card-body .basic-details h1{
    margin: 0px;
}
.invoice-view  .ant-card .ant-card-body .ant-divider{
    margin: 0px;
}
/* .image-actions > div{
    background: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-left: 5px;
    cursor: pointer;
}
.asset-detail .image-actions>div:hover{
    background-color: var(--secondary);
    opacity: 0.5;
} */


@media only screen and (max-width: 1024px) {
    .invoice-view  .container {
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
    .invoice-view  .app-header {
        width: 90%;
    }
    .invoice-view  .app-header .header-container {
        max-width: 100%;
        margin: 0;
        width: 100% !important
    }
}
@media only screen and (max-width: 420px) {
    .invoice-view  .app-header .ant-menu-item{
        padding-right: 0
    }
    /* .invoice-view  .ant-card .ant-avatar {
        width: 100px !important;
    } */
    .invoice-view  .ant-form .ant-form-item {
        margin-bottom:10px
    }
    .invoice-view  .ant-form .ant-form-item .ant-form-item-label {
        padding: 0
    }
    .invoice-view  .ant-list-item-meta-description .ant-btn:first-of-type {
        margin-bottom: 5px;
    }

    .image {
        position: relative;
        height: 200px;
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .invoice-view  .image-container.empty{
        height: 200px;
        position: relative;
    }
}


.store .store-title h1{
    margin: 10px;
    text-align: center;
    color: #000;
    font-weight: 700;
}

.store .store-title .ant-card-body{
    background-color:#fff
}

@media only screen and (max-width: 420px) {
    .store .store-title h1{
        line-height: 1.2;
    }

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout{
  min-height: 100vh !important;
  overflow-x: scroll;
  background-color: #E9EDF5
}
.ant-layout-content {
  background-color: #E9EDF5;
  overflow-y: scroll;
  height: 100vh;
}
.page-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: calc(100vh - 64px);
  /* background-color: RGBA(240, 242, 245, 1.00); */
}


.empty-large.ant-empty {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-self: center;
          align-self: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 80%;
}
.empty-large .ant-empty-image{
  height: 50vh !important;
}
.empty-large .ant-empty-image img{
  width: 100%;
}
.empty-large p{
  font-size: 18px;
}
.empty-large p a{
  font-weight: bold;
}


/* Page Heading */
.page-content {
  margin: 0px auto;
  padding-top: 50px;
  width: 90%;
  max-width: 800px;
}

.page-content .heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 10px
}

.page-content .heading h3 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
}

.page-content .heading .button-container button {
  margin: 0px 5px;
  font-weight: 700;
}







/* 
----------------------------------
----------------------------------
----------------------------------
------ CUSTOM STYLES ANTD --------
----------------------------------
----------------------------------
----------------------------------
*/

/* Card */
.ant-card{
  overflow: hidden;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.025);
}

/* Modal */
.ant-modal-content {
  overflow: hidden;
  border-radius: 16px;
}
.ant-modal-content .ant-modal-header{
  border: none;
  padding-bottom: 0;
}
.ant-modal-content .ant-modal-footer {
  border: none;
  padding-top: 0;
}

/* Table */
.ant-table{
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.025);
}
.ant-table .ant-table-expanded-row td{
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.ant-table.ant-table thead>tr>th{
  background-color:RGBA(226, 148, 86, 0.075);
  font-weight: 600;
  /* font-size: 16px; */
}
.ant-table tr>td{
  background-color: #fff
}

/* Alert */
.ant-alert{
  border: none !important;
  margin-top: 10px !important;
}

/* BreadCrumb */
.ant-breadcrumb{
  font-size:20px !important
}
.ant-breadcrumb a{
  color: var(--secondary) !important;
  font-weight: bold;
  /* border-bottom: 2px solid var(--secondary); */
}
.ant-breadcrumb .ant-btn {
  font-weight: bold;
  font-size: 20px;
  padding-right: 0;
  padding-left: 5px;
}
.ant-breadcrumb .ant-btn .anticon{
  font-size: 20px;
}

/* Form */
.ant-form-vertical .ant-form-item-label{
  padding-bottom: 3px !important;
}
.ant-form-vertical .ant-form-item{
  margin-bottom: 16px !important;
}
.custom-table-row {
  background-color:#ccc !important;
}

/* Ant Tag */
.ant-tag{
  text-transform: capitalize;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


