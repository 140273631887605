.splash-screen{
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    align-self: center;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
    background: rgb(241, 159, 95);
    background: -moz-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%);
    background: linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f19f5f", endColorstr="#e06631", GradientType=1);
}
.splash-screen img.bg{
    position: relative;
    width: 100%;
    object-fit: contain;
    z-index: 999;
    animation:fade 2500ms infinite;
    -webkit-animation:fade 2500ms infinite;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}