.login-page{
    background-color: #fff;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    align-self: center;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
}
.login-page .page-content{
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
}
.login-page img.bg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    z-index: 9;
}
.login-page .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.36);
}
.login-page .page-content{
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: row;
}
.login-page .page-content .ant-row{
    width: 100%;
}
.login-page .ant-card.parent{
    z-index: 999;
    max-width: 960px;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
.login-page .ant-card.parent .ant-card-body{
    flex-direction: row;
    display: flex;
    padding: 0;
}
.login-page .ant-card.parent .img-holder{
    /* margin-right: 2px; */
    width:100%;
    height: 500px;
    background-color: RGBA(232, 130, 71, 0.13);
    position: relative
}
.login-page .ant-card.parent .img-holder img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover
}
.login-page .ant-card.parent .form-container{
    /* margin-right: 2px; */
    padding: 0 25px;
    display: flex;
    flex-direction:column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
}
.login-page .ant-card.parent .form-container img.logo{
    width: 200px;
    margin-bottom: 36px
}


.login-page .page-content .form{
    padding: 10px 0px;
    justify-content:center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 360px;
}

.login-page .page-content .form h2{
    font-weight: bold;
    color: #999;
}

.login-page .page-content .errMsg{
    margin-bottom: 20px;
}

.login-page .page-content .form .inner{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
}
.login-page .page-content .form .inner .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label{
    display: flex;
    width: 100%;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
}
.login-page .page-content .ant-card-rtl .form .inner .react-tel-input {
    direction: ltr;
}
.login-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}

.login-page .page-content .form .inner .item{
    font-size: 16px;
    flex-grow: 1;
}

.login-page .page-content .link{
    font-size: 16px;
    margin: 0px 100px;
    max-width: 400px;
}


/* .login-page .left-poster{
    background: linear-gradient(135deg,  #f19f5f 30%,#e06631 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f19f5f', endColorstr='#e06631',GradientType=1 );
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-page .left-poster img{
    width: 70%;
    align-self: center;
    z-index: 2;
}

.login-page .left-poster .overlay-gradient {
    background: linear-gradient( to left, rgba(68, 66, 132, 0.3) 50%, rgba(68, 66, 132, 0.3) 100% );
    left: 0;
    top: 0;
    height: 100%;
    width: 40%;
    position: absolute;
    z-index: 1;
}

.login-page .page-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100vh;
    position: relative;
}
.login-page .page-content img{

}

.login-page .page-content .header-container{
    display: none;
}

.login-page .page-content .form{
    padding: 10px 100px;
    justify-content:center;
    display: flex;
    flex-direction: column;
    max-width: 640px;
}

.login-page .page-content .form h2{
    font-weight: 700;
    color: var(--secondary);
}

.login-page .page-content .errMsg{
    margin-bottom: 20px;
}

.login-page .page-content .form .inner{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
    max-width: 400px;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(1){
    padding-right: 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(2){
    padding: 5px 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8:nth-child(3){
    padding-left: 0px!important;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label{
    display: flex;
    width: 100%;
}

.login-page  .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
}


.login-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}

.login-page .page-content .form .inner .item{
    font-size: 16px;
    flex-grow: 1;
}

.login-page .page-content .link{
    font-size: 16px;
    margin: 0px 100px;
    max-width: 400px;
}

@media only screen and (max-width: 1024px) {
    .login-page .left-poster{
        display: none;
    }

    .login-page .page-content .header-container{
        display: block;
    }

    .login-page .page-content .form-container,.login-page .page-content .link-container {
        display: flex;
        justify-content: center;
    }

    .login-page .page-content .form-container .form{
        margin:20px 0px;
        padding: 0px;
        width: 400px;
    }

}

@media only screen and (max-width: 420px) {
    .login-page .page-content .form-container .form{
        margin:0px 20px;
    }

    .login-page .page-content .link{
        margin: 0px 20px;
    }
} */