.contact-details .ant-col .ant-row{
    margin-bottom: 0px;
}

.contact-details .input-item{
    width: 100%
}
.contact-details .react-tel-input .form-control{
    width: 100%;
    height: 30px
}

.contact-details .errMsg{
    width: 100%;
}