.store .store-title h1{
    margin: 10px;
    text-align: center;
    color: #000;
    font-weight: 700;
}

.store .store-title .ant-card-body{
    background-color:#fff
}

@media only screen and (max-width: 420px) {
    .store .store-title h1{
        line-height: 1.2;
    }

}