.settings-page .page-content .content{
    display: flex;
    width: 100% ;
    flex-direction: column;
}

.settings-page .page-content .ant-card-body{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: flex-start
}
.settings-page .page-content .ant-card-body .ant-row{
    width: 100%;
}
.settings-page .page-content .video{
    width: 100% !important;
    height: 250px !important;
    border-radius: 8px;
    overflow: hidden;
}
/* .settings-page .page-content .ant-card-body::before,
.settings-page .page-content .ant-card-body::after {
    display: none;
} */
.settings-page .page-content .ant-card-body .form-col {
    display: flex;
    align-items: center;
}
.settings-page .page-content .img-holder{
    /* width:320px; */
    height: 250px;
    position: relative;
}
.settings-page .page-content .img-holder img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: cover
}

.settings-page .page-content .form-container .form,
.settings-page .page-content .form-container .item {
    width: 100%;
}

/* .settings-page .page-content .form-container .form .inner .ant-col.ant-col-8:nth-child(1){
    padding-right: 0px!important;
}

.settings-page .page-content .form-container .form  .inner .ant-col.ant-col-8:nth-child(2){
    padding: 5px 0px!important;
}

.settings-page .page-content .form-container .form  .inner .ant-col.ant-col-8:nth-child(3){
    padding-left: 0px!important;
}

.settings-page .page-content .form .inner .ant-col.ant-col-8 label{
    display: flex;
    width: 100%;
} */

/* .settings-page .page-content .form .inner .ant-col.ant-col-8 label span:nth-child(2){
    text-align: center;
    width: 100%;
} */
.settings-page .page-content .form .inner .ant-row-rtl .react-tel-input {
    direction: ltr;
}
.settings-page .page-content .form .inner .react-tel-input .form-control{
    width: 100%;
}
.settings-page .page-content .form .inner .ant-radio-button-wrapper{
    text-align: center;
    width: 100%;
}
.settings-page .page-content .form .ant-form-item{
    margin: 0
}
.settings-page .page-content .form .inner {
    margin: 15px auto
}

/* .settings-page .page-content .form .inner .item{
    font-size: 16px;
    flex-grow: 1;
} */

/* .settings-page .page-content .form .inner .ant-row{
    margin-bottom: 10px;
} */

/* .settings-page .page-content .form .inner .ant-btn{
    margin-top: 10px;
} */