
.shared-file-viewer-page .banner-prompt {  
    width: 100%;
    position: fixed;
    z-index: 7;
    bottom: 0px;
    box-shadow: 6px 6px 21px -9px rgba(0,0,0,0.75);
}
.shared-file-viewer-page .banner-prompt .row{
    display: flex;
    justify-content: space-between;
    color: var(--secondary);
}
.shared-file-viewer-page .banner-prompt .ant-page-header-heading-left,.shared-file-viewer-page .banner-prompt .ant-page-header-heading-left .ant-page-header-heading-title{
    width: 100%;
}
.banner-prompt.app .row div:nth-child(1){
    width: 95%;
    text-align: center
}
.banner-prompt.app  .row div:nth-child(2){
    width: 5%;
    text-align: right;
  }

