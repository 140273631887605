.shared-asset-viewer-page{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background: rgb(241, 159, 95) !important; */
    /* background: -moz-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    background: -webkit-linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    background: linear-gradient(135deg, rgba(241, 159, 95, 1) 0%, rgba(224, 102, 49, 1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f19f5f", endColorstr="#e06631", GradientType=1); */
 
    background-color: #EAEDF4 !important;
 }
 
 .shared-asset-viewer-page .app-header{
     width: 80%;
     background: transparent !important;
     box-shadow:none;
     margin: 10px 0px
 }
 
 .shared-asset-viewer-page .ant-layout-header{
     padding: 0px;
 }
 
 .shared-asset-viewer-page .app-header .header-container {
     padding: 0px 10px;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin: 0 auto;
     position: relative;
 }
 
 
 .shared-asset-viewer-page .app-header .logo {
     width: auto;
     height: 100%;
     margin: 0;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .shared-asset-viewer-page .app-header .logo img {
     height: 70%;
     object-fit: contain;
 }
 .shared-asset-viewer-page .app-header .ant-menu{
     background: transparent;
     border: none;
 }
 .shared-asset-viewer-page .app-header .ant-menu-item {
     font-size: 18px;
     font-weight: bold;
     /* color: var(--secondary); */
 }
 .shared-asset-viewer-page .app-header .ant-btn{
     font-weight: bold;
     /* border-color: #fff;
     color: #fff; */
 }
 .shared-asset-viewer-page .app-header .ant-btn:hover{
     opacity: 0.75;
     /* background-color: rgba(255, 255, 255,0.5) !important; */
 }
 
 .shared-asset-viewer-page .container{
     height: 100%;
     width: 100%;
     max-width: 80%;
     overflow-y: scroll;
     padding: 0px;
     margin: 0px;
     display: flex;
 }
 
 .shared-asset-viewer-page .container .images-list .ant-list-item-meta-content{
     align-self: center;
 }
 .shared-asset-viewer-page .ant-list-item-meta-description .doc-desc{
     display:flex;
     justify-content: space-between;
     align-self: center;
 }
 
 .shared-asset-viewer-page .ant-list-item-meta-description .doc-desc h3{
     font-size: 14px;
     color: grey;
     font-weight: 600;
 }
  
 .shared-asset-viewer-page .container .collapse .anticon.anticon-right.ant-collapse-arrow{
     color: #E88247;
     margin-right: -15px
 }
 .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item{
     background: #fff;
     border: 0px;
     padding: 10px 20px;
 }
 
 .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item .ant-collapse-header, .shared-asset-viewer-page .container .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
     padding: 0;
 }
 
 .shared-asset-viewer-page .ant-list-item-meta-description .ant-btn:first-of-type {
     margin-right: 10px;
     color: var(--secondary);
 }
 
 .shared-asset-viewer-page textarea.ant-input {
     min-height: 80px;
 }
 .shared-asset-viewer-page .ant-input[disabled]{
     color:grey;
     font-weight: 600;
 }
 .image-container{
     width: 100%;
     height: 100%;
     object-fit: contain;
     display: flex;
     justify-content: center;
     background: RGBA(226, 115, 32, 0.13); 
 }
 
 .image-container.empty{
     height: 350px;
     position: relative;
 }
 
 .image-container.empty img{
     width: 250px;
 }
 
 .image {
     position: relative;
     height: 350px;
     width: 300px;
     display: flex !important;
     justify-content: center;
 }
 
 .shared-asset-viewer-page .ant-carousel .slick-slide img {
     display: block;
     max-width: 100%;
     object-fit: cover;
 }
 .shared-asset-viewer-page .ant-carousel .slick-dots li,.shared-asset-viewer-page .ant-carousel .slick-dots li.slick-active{
     width: auto;
 }
 .shared-asset-viewer-page .ant-carousel .slick-dots li button {
     width: 6px !important;
     height: 5px !important;
     border-radius: 10px !important;
 }
 .shared-asset-viewer-page .ant-input[disabled]{
     background-color: #fff;
     border: 0px;
     padding: 0px;
     resize: none;
 }
 
 .shared-asset-viewer-page .image-actions{
     position: absolute;
     top: 10px;
     right: 10px;
     display: flex;
     flex-direction: row
 }
 
 .shared-asset-viewer-page .image-actions .ant-btn{
     color: var(--secondary);
 }
 /* .shared-asset-viewer-page .ant-card {
     border-radius: 0px;
 } */
 .shared-asset-viewer-page .ant-card .ant-card-body{
     padding: 0px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details{
     padding: 10px 20px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details .name-btn{
     display: flex;
     /* justify-content: space-between; */
     flex-direction: column;
     margin-bottom: 15px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details .name-btn .btn-container{
     display: flex;
     flex-direction: column;
     justify-content: center;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .basic-details h1{
     margin: 0px;
 }
 .shared-asset-viewer-page .ant-card .ant-card-body .ant-divider{
     margin: 0px;
 }

 .shared-asset-viewer-page .ant-collapse-rtl > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
     right: auto;
     left: 0px;
 }
 /* .image-actions > div{
     background: #fff;
     padding: 3px 6px;
     border-radius: 4px;
     border: 1px solid #eee;
     margin-left: 5px;
     cursor: pointer;
 }
 .asset-detail .image-actions>div:hover{
     background-color: var(--secondary);
     opacity: 0.5;
 } */
 
 
 @media only screen and (max-width: 1024px) {
     .shared-asset-viewer-page .container {
         max-width: 90%;
         padding-left: 0;
         padding-right: 0;
     }
     .shared-asset-viewer-page .app-header {
         width: 90%;
     }
     .shared-asset-viewer-page .app-header .header-container {
         max-width: 100%;
         margin: 0;
         width: 100% !important
     }
 }
 @media only screen and (max-width: 420px) {
     .shared-asset-viewer-page .app-header .ant-menu-item{
         padding-right: 0
     }
     /* .shared-asset-viewer-page .ant-card .ant-avatar {
         width: 100px !important;
     } */
     .shared-asset-viewer-page .ant-form .ant-form-item {
         margin-bottom:10px
     }
     .shared-asset-viewer-page .ant-form .ant-form-item .ant-form-item-label {
         padding: 0
     }
     .shared-asset-viewer-page .ant-list-item-meta-description .ant-btn:first-of-type {
         margin-bottom: 5px;
     }
 
     .image {
         position: relative;
         height: 200px;
         display: flex !important;
         justify-content: center;
     }
 
     .shared-asset-viewer-page .image-container.empty{
         height: 200px;
         position: relative;
     }
     .shared-asset-viewer-page .app-header .logo img {
         height: 60%;
     }
 }
 
 