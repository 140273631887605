.contacts-table .ant-avatar{
  width: 48px !important;
  height: 48px !important;
  color: #9f9f9f;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
  background-color: RGBA(251, 235, 226, 1.00);
}