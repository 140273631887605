.add-inquiry-modal .ant-col .ant-row{
    margin-bottom: 0px;
}

.add-inquiry-modal .input-item{
    width: 100%
}
.add-inquiry-modal .react-tel-input .form-control{
    width: 100%;
    height: 30px
}

.add-inquiry-modal .errMsg{
    width: 100%;
}