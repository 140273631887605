.doc-section{
    background-color: white;
    width: 100%;
    margin: 0px 0px;
}

.doc-section .header-section{
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 25px; */
    /* height: 40px; */
}

.doc-section .header-section p {
    font-weight: 500;
}

.doc-section .doc-container{
    /* height: 72%; */
    /* max-height: 150px;
    overflow: scroll; */
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.doc-section .doc-container .doc-row{
    margin-bottom:10px
}
.doc-section .doc-container .ant-form,
.doc-section .doc-container .ant-form-item,
.doc-section .doc-container .ant-form-item .ant-picker {
    width: 100%;
}
.doc-section .doc-container .image-container img{
    height: 80px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 2px 8px 10px 0px rgba(0,0,0,0.13);
}
.doc-section .doc-container .input-container{
    align-items: center;
    display: flex;
}
.doc-section .doc-container .action-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.doc-section .doc-container .action-container .ant-form-item-control-input-content {
    justify-content: flex-end;
    align-self: flex-end;
    display: flex;
}
