
.rentals-page .page-content .heading .button-container button.rental{
    background-color:#2ecc71;
    border: #2ecc71;
}

.rentals-page .page-content .heading .button-container button.expense{
    background-color:#e74c3c;
    border: #e74c3c;
}
.rentals-page .page-content .report-container h3 {
    margin: 0;
}
.rentals-page .page-content .report-container .ant-btn-link {
    padding-left: 0;
}
.rentals-page .page-content .report-container > div{
    /* background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    height: 100%; */
}
.rentals-page .page-content .report-container .ant-row{
    width: 100%;
}
.rentals-page .page-content .report-container>div::before,
.rentals-page .page-content .report-container>div::after{
    display: none;
}

.rentals-page .page-content .report-container > div > div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: space-around;
}

.rentals-page .page-content .report-container > div > div p{
    margin: 0;
}
.rentals-page .page-content .report-container .amount{
    text-align: right;
}
.rentals-page .page-content .report-container .amount p:nth-child(2){
    font-size: 18px;
}