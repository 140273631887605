.connected-rental{
    width: 100%;
    margin: auto !important;
    transition: all 0.4s ease-in-out;
    /* margin-bottom: 20px !important; */
}

.connected-rental .button-container button{
    margin: 0px 5px;
    font-weight: 700;
}

.connected-rental .button-container button.rental{
    background-color:#2ecc71;
    border: #2ecc71;
}

.connected-rental .button-container button.expense{
    background-color:#e74c3c;
    border: #e74c3c;
}

.connected-rental .action-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}