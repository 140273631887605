.add-contact-modal .ant-col .ant-row{
    margin-bottom: 0px;
}

.add-contact-modal .input-item{
    width: 100%
}
.add-contact-modal .react-tel-input .form-control{
    width: 100%;
    height: 30px
}
.add-contact-modal .react-tel-input .country-list{
    position: fixed !important;
}
.add-contact-modal .errMsg{
    width: 100%;
}
.add-contact-modal .react-tel-input .country-list .search-box{
    width: 90%;
}
