.add-receipt .amount{ 
    border: 1px solid #eeeeee;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
    width: 100%;
}

.add-receipt .amount:focus{
  outline: none;
  border: 1px solid #f5a772;
}

.add-receipt .images{
    position: relative;
}

.add-receipt .images .close-icon{
    position: absolute;
    z-index: 1;
    left: 85px;
    color: red;
}

.add-receipt .images .image {
    display: block;
    width: 100%;
    height: auto; 
    border: 1px solid #eeeeee;
  }
.add-receipt .images .image img{
  padding: 10px;
}
.add-receipt .images .overlay {
    position: absolute;
    bottom: 10px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100px;
    height: 100px;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .add-receipt .images .overlay a{
    color: #fff;
  }
  .add-receipt .images .overlay .anticon svg{
    font-size: 16px;
  }
  .images:hover .overlay {
    opacity: 1;
  }