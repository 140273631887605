.asset-details{
   display: flex;
   /*flex-direction: column;*/
   width: 100%;
   margin: 0px auto;
   transition: all 0.4s ease-in-out;
   min-height: calc(100vh -64px);
   justify-content: flex-end;   
}
.asset-details.fade-in{
    opacity: 1;
    transform: translateY(0)
}
.asset-details.fade-out{
    opacity: 0;
    transform: translateY(50px)
}

.asset-details .buttons{
    display: flex;
    justify-content: flex-end;
}

.asset-details .buttons button:nth-child(2){
    margin-left: 10px;
}

.asset-details .basic{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
}

.asset-details .basic > .ant-card-body{
    display: flex;
    width: 100%;
    padding: 0;
}

.asset-details .basic > .ant-card-body .ant-carousel{
    width: 50%;
}
.asset-details .basic .image-container{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    background: RGBA(226, 115, 32, 0.13);
}

.asset-details .basic .image-container.empty{
    width: 50%;
    position: relative;
}

.asset-details .basic .image-container .image {
    position: relative;
    height: 250px;
    display: flex;
    justify-content: center;
}

.asset-details .image-actions{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row
}
.asset-details .image-actions > div{
    background: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-left: 5px;
    cursor: pointer;
}
.asset-details .image-actions>div:hover{
    background-color: var(--secondary);
    opacity: 0.5;
}

/* .asset-details .image-actions div>span */

/* .asset-details .basic .image-container .image>.image-actions:nth-child(2) {
    right: 30px;
    top: 3px;
} */

/* .asset-details .image-actions>span {
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.asset-details .basic .image-container .image .ant-upload span{
    font-size: 20px;
    color: white;
    cursor: pointer;
} */

.asset-details .basic .image-container .slick-list{
    width: 100%;
    height: 100%;
}
/* .asset-details .basic .image-container .ant-card,.asset-details .basic .image-container .ant-card-body{
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

.asset-details .basic .image-container .ant-card-body{
    display: flex;
    justify-content: center;
}

.asset-details .basic .image-container img{
    width: 100%;
    height: 250px;
    object-fit: contain;
}

.asset-details .basic .basic-details{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.asset-details .basic .basic-details > div{
    margin: 5px 0px;
}

.asset-details .basic .basic-details p{
    margin: 0px;
}

.asset-details .basic .basic-details .ant-select{
    width: 120px;

}

/* 
.asset-details .doc-section .doc-container .doc-row .image-container{
    height: 100px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 15px;
}

.asset-details .doc-section .doc-container .doc-row{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}


.asset-details .doc-section .doc-container .doc-row .image-container img{
    height: 100%;
    width: 100%;
}

.asset-details .doc-section .doc-container .doc-row .input-container{
    display: flex;
}     */

/* .asset-details .doc-section .doc-container .doc-row .input-container p{
    margin: 5px;
}

.asset-details .doc-section .doc-container .doc-row .input-container.name input{
    width: 250px;
}

.asset-details .doc-section .doc-container .ant-empty .ant-empty-image{
    opacity: 0.8;
} */