.add-asset-modal .form p{
    margin-top: 20px;
}

.add-asset-modal .form .errMsg{
    width: 100%;
}

.add-asset-modal .form .ant-row{
    margin-bottom: 0px;
}
.add-asset-modal .form .status .ant-select{
    width: 100%;
}

.add-asset-modal .form .date-picker div.ant-picker.input-item{
    width: 100%;
}

.add-asset-modal .form .doc-type span.ant-tag{
    width: 100%;
    height: 100%;
    padding-top: 2%;
    text-align: center;
    font-size: 14px;
}
