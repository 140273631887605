.rental-details .input-item {
    width: 100% !important;
}

.rental-details .input-item.rate {
    border: none;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
}

.rental-details .input-item.rate::placeholder{
    color: #c4c4c4
}

.rental-details .input-item.rate:hover, .payment-details .input-item.rate:focus{
    outline: none;
    border: 1px solid #f5a772;
}


.rental-details .ant-row{
    margin-bottom: 10px;
}

.rental-details .hidden{
    display: none;
}

.rental-details .heading .back{
    font-size: 20px;
    font-weight: 600;
}
