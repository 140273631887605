.sidebar {
    background: #fff !important
}

.sidebar .sidebar-inner-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar .sidebar-inner-container .logo-container img.sidebar-logo {
    width: 100%;
    padding: 36px 42px 50px
}

.sidebar .ant-menu-item {
    background-color: transparent !important;
    color: RGBA(26, 42, 78, 0.5);
    font-weight: 600;
    /* font-size:16px; */
    height: 50px !important;
    line-height: 50px !important;
    padding-left: 56px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    transition: all 0.4s ease-in-out !important;
}

.sidebar .ant-menu-item.ant-menu-item-selected {
    background-color: RGBA(232, 130, 71, 0.136) !important;
    color: var(--secondary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 16px;
    width: calc(100% - 15px);
    box-shadow: 3px 3px 10px 4px rgba(0, 0, 0, 0.036);
}

.sidebar .sidebar-inner-container .stats-container {
    margin-top:auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 20px;
}
.sidebar .sidebar-inner-container .stats-container .ant-card{
    background: RGBA(250, 239, 231, 1.00);
    box-shadow: none;
}
.sidebar .sidebar-inner-container .stats-container .ant-statistic-title {
    /* color: rgba(0, 0, 0, 0.6); */
    color: var(--secondary);
    font-weight: 600;
    text-transform: uppercase;
}
.sidebar .sidebar-inner-container .stats-container .ant-statistic-content-value {
    font-weight: 600;
}