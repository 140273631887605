.add-rental-modal .input-item{
    width: 100%;
}

.add-rental-modal .input-item.rate {
    border: 1px solid #eeeeee;
    background-color: #fbfbfd;
    border-radius: 4px;
    padding: 4px 10px;
}

.add-rental-modal .input-item.rate::placeholder{
    color: #c4c4c4
}

.add-rental-modal .input-item.rate:hover, .add-rental-modal .input-item.rate:focus{
    outline: none;
    border: 1px solid #f5a772;
}

.add-rental-modal .ant-row{
    margin-bottom: 0px;
}

.add-rental-modal .errMsg{
    width: 100%;
}

.add-rental-modal .ant-form-item-label{
    padding-bottom: 3px !important; 
}

.add-rental-modal .hidden{
    display: none;
}