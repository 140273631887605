.download-page{
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    align-self: center;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
}
.download-page img.bg {
    position: relative;
    width: 90%;
    object-fit: contain;
    z-index: 999;
    margin-bottom: 36px;
    /* animation:fade 2500ms infinite;
    -webkit-animation:fade 2500ms infinite; */
}
.download-page .app-badges img{
    width: 100%;
    margin-bottom:15px
}
.download-page h3{
    text-align: center;
    font-weight: bold;
    font-size:18px;
    line-height:24px;
    margin-top: 10px;
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 1.0;
    }
}